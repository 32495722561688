<template>
  <div>
    <b-modal
      ref="modalDataShareLead"
      :title-html="$t('Enviar {name} como referência para:', { name: '<b>' + nameLead + '</b>'})"
      cancel-variant="outline-secondary"
      size="lg"
      body-class=""
      :visible="false"
      :ok-only="false"
      :ok-title="$t('Enviar')"
      :cancel-title="$t('Fechar')"
      :cancel-disabled="showProcessing"
      :ok-disabled="showProcessing"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="confirmModal"
      @cancel="closeModal"
    >
      <b-overlay
        :show="showProcessing"
        rounded="sm"
      >
        <validation-observer
          ref="formShareReference"
          #default="{}"
        >
          <b-row>
            <b-col md="12">
              <b-alert
                variant="warning"
                show
              >
                <h4 class="alert-heading">
                  {{ $t('Aviso') }}
                </h4>
                <div class="alert-body">
                  {{ $t(' O Lead que está a enviar será apenas considerado como referência caso cumpra a intenção de negócio. Para qualquer outra, fruto do trabalho do consultor que a recebe, a referência não será considerada. Para esclarecimentos adicionais, consulte o manual de procedimentos.') }}
                </div>
              </b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  :label="$t('Consultor')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-model="consultor"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="agents"
                    append-to-body
                    :calculate-position="withPopper"
                    :filterable="false"
                    label="desc"
                    item-text="desc"
                    item-value="id"
                    @search="onSearchConsultoresAppSelect"
                  >
                    <div
                      v-if="agents.length > 0"
                      slot="no-options"
                    >
                      {{ $t('Sem resultados') }}
                    </div>
                    <template
                      v-if="agents.length === 0"
                      slot="no-options"
                    >
                      {{ $t('Digite o nome') }}
                    </template>
                    <template
                      slot="option"
                      slot-scope="option"
                    >
                      <div class="d-flex align-items-center">
                        <b-avatar
                          :src="option.photo"
                          class="mr-50"
                        />
                        <div class="detail">
                          <b-card-text class="mb-0">
                            {{ option.desc }}
                          </b-card-text>
                          <b-card-text
                            v-if="option.email"
                            class="mb-0 text-muted"
                          >
                            <small>{{ option.email }}</small>
                          </b-card-text>
                          <b-card-text
                            v-if="option.phone"
                            class="mb-0 text-muted"
                          >
                            <small>{{ option.phone }}</small>
                          </b-card-text>
                        </div>
                      </div>
                    </template>
                    <template
                      slot="selected-option"
                      slot-scope="option"
                    >
                      {{ option.desc }}
                    </template>
                  </v-select>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  :label="$t('Valor do negócio')"
                >
                  <b-form-spinbutton
                    v-model="valorNegocio"
                    min="0"
                    max="100"
                    :formatter-fn="formatValorNegocio"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row
            v-if="hubs.length > 0"
          >
            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  :label="$t('HUB de origem')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-model="hubOrigem"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="hubs"
                    append-to-body
                    :calculate-position="withPopper"
                    label="sw002s03"
                    item-text="sw002s03"
                    item-value="sw002s01"
                  >
                    <template #option="{ sw002s03 }">
                      {{ sw002s03 }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  :label="$t('Intenção da referência')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-model="intencaoReferencia"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataIntencao"
                    append-to-body
                    :calculate-position="withPopper"
                    label="txt"
                    item-text="txt"
                    item-value="id"
                  >
                    <template #option="{ txt }">
                      {{ txt }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="$t('Observações')"
              >
                <b-form-textarea
                  v-model="observacao"
                  rows="3"
                  no-resize
                />

              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>

        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              {{ $t('Aguarde, a processar...') }}
            </p>
          </div>
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BCardText, BAvatar, BAlert, BFormTextarea, BFormSpinbutton, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import btoa from 'btoa'
import checkNested from 'check-nested'
import { debounce } from '@core-custom/mixins/geral'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BModal,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BCardText,
    BAvatar,
    BAlert,
    BFormTextarea,
    BFormSpinbutton,
    vSelect,
    BOverlay,
  },
  mixins: [mixFormGeral],
  data() {
    return {
      keyReg: null,
      nameLead: null,
      consultor: null,
      agents: [],
      valorNegocio: null,
      intencaoReferencia: null,
      hubOrigem: null,
      hubs: [],
      observacao: null,
      dataIntencao: [{
        id: 2,
        txt: this.$t('Compra'),
      },
      {
        id: 1,
        txt: this.$t('Venda'),
      }],
      showProcessing: false,
    }
  },
  methods: {
    showModal(params) {
      return new Promise((resolve, reject) => {
        this.consultor = null
        this.agents = params.agents || []
        this.valorNegocio = 25
        this.intencaoReferencia = null
        this.hubOrigem = null
        this.hubs = params.hubs || []
        this.observacao = null
        this.nameLead = params.nameLead || ''
        this.keyReg = params.keyReg || null

        this.$refs.modalDataShareLead.show()
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    hideModal() {
      this.consultor = null
      this.agents = []
      this.valorNegocio = null
      this.intencaoReferencia = null
      this.hubOrigem = null
      this.hubs = []
      this.observacao = null
      this.nameLead = ''
      this.keyReg = null
      this.showProcessing = false
      this.$refs.modalDataShareLead.hide()
    },
    formatValorNegocio(value) {
      return `${value} %`
    },
    async confirmModal(evt) {
      evt.preventDefault()
      await this.$refs.formShareReference.validate()
        .then(async valid => {
          if (valid === true) {
            if (('id' in this.consultor) && (this.consultor.id !== '') && ('desc' in this.consultor) && (this.consultor.desc !== '')) {
              await this.$swal({
                title: '',
                html: `${this.$t('Vai enviar referência do Lead para {name}. Tem a certeza?', { name: `<b>${this.consultor.desc}</b>` })}`,
                icon: 'info',
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: this.$t('Sim'),
                cancelButtonText: this.$t('Não'),
                customClass: {
                  confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
                  cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
                },
                buttonsStyling: false,
              }).then(async result => {
                if (result.value) {
                  let txtMessageInfo = this.$t('A comunicação é o segredo do negócio, não esqueça de falar com o colega {name}.', { name: `<b>${this.consultor.desc}</b>` })

                  if ('phone' in this.consultor) {
                    txtMessageInfo = this.$t('A comunicação é o segredo do negócio, não esqueça de falar com o colega {name}, com o tel: {phone}.', { name: `<b>${this.consultor.desc}</b>`, phone: `<b>${this.consultor.phone}</b>` })
                  }

                  if (this.keyReg !== null) {
                    this.showProcessing = true
                    await this.$store.dispatch('crm_leads_events/saveChooseConsultorSendRef', {
                      consultor: btoa(this.consultor.id),
                      valornegocio: this.valorNegocio,
                      intencaoRef: ((checkNested(this.intencaoReferencia, 'id')) ? this.intencaoReferencia.id : null),
                      HUBOrigem: ((checkNested(this.hubOrigem, 'sw002s01')) ? btoa(this.hubOrigem.sw002s01) : null),
                      observacoes: this.observacao,
                      keyReg: this.keyReg,
                    }).then(async res => {
                      this.showProcessing = false
                      this.$refs.modalDataShareLead.hide()
                      this.resolvePromise({
                        message: res,
                        share: true,
                        messageInfo: txtMessageInfo,
                      })
                    }).catch(error => {
                      this.showProcessing = false
                      this.showMsgErrorRequest(error)
                    })
                  } else {
                    this.resolvePromise({
                      consultor: btoa(this.consultor.id),
                      valornegocio: this.valorNegocio,
                      intencaoRef: ((checkNested(this.intencaoReferencia, 'id')) ? this.intencaoReferencia.id : null),
                      HUBOrigem: ((checkNested(this.hubOrigem, 'sw002s01')) ? btoa(this.hubOrigem.sw002s01) : null),
                      observacoes: this.observacao,
                      share: false,
                      messageInfo: txtMessageInfo,
                    })
                  }
                }
              })
            } else {
              this.showMsgErrorRequest({ message: this.$t('Escolha um consultor') })
            }
          } else if (valid === false) {
            this.showMsgErrorRequest({ message: this.$t('Escolha um consultor') })
          }
        })
    },
    async closeModal() {
      this.rejectPromise({ message: '' })
    },
    onSearchConsultoresAppSelect(search, loading) {
      if (search.length) {
        loading(true)
        this.searchConsultoresAppSelect(loading, search, this)
      }
    },
    searchConsultoresAppSelect: debounce((loading, search, vm) => {
      const oThis = vm

      vm.$store.dispatch('crm_leads_events/chooseConsultorSendRef', { keyReg: vm.keyReg, txt: search }).then(res => {
        oThis.agents = res.agents
        loading(false)
      }).catch(error => {
        vm.showMsgErrorRequest(error)
        oThis.agents = []
        loading(false)
      })
    }, 500),
  },
}
</script>
