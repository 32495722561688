var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modalDataShareLead",attrs:{"title-html":_vm.$t('Enviar {name} como referência para:', { name: '<b>' + _vm.nameLead + '</b>'}),"cancel-variant":"outline-secondary","size":"lg","body-class":"","visible":false,"ok-only":false,"ok-title":_vm.$t('Enviar'),"cancel-title":_vm.$t('Fechar'),"cancel-disabled":_vm.showProcessing,"ok-disabled":_vm.showProcessing,"ok-variant":"primary","no-close-on-esc":"","no-close-on-backdrop":"","hide-header-close":""},on:{"ok":_vm.confirmModal,"cancel":_vm.closeModal}},[_c('b-overlay',{attrs:{"show":_vm.showProcessing,"rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('p',{attrs:{"id":"cancel-label"}},[_vm._v(" "+_vm._s(_vm.$t('Aguarde, a processar...'))+" ")])])]},proxy:true}])},[_c('validation-observer',{ref:"formShareReference",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.$t('Aviso'))+" ")]),_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.$t(' O Lead que está a enviar será apenas considerado como referência caso cumpra a intenção de negócio. Para qualquer outra, fruto do trabalho do consultor que a recebe, a referência não será considerada. Para esclarecimentos adicionais, consulte o manual de procedimentos.'))+" ")])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('Consultor')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.agents,"append-to-body":"","calculate-position":_vm.withPopper,"filterable":false,"label":"desc","item-text":"desc","item-value":"id"},on:{"search":_vm.onSearchConsultoresAppSelect},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{staticClass:"mr-50",attrs:{"src":option.photo}}),_c('div',{staticClass:"detail"},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(option.desc)+" ")]),(option.email)?_c('b-card-text',{staticClass:"mb-0 text-muted"},[_c('small',[_vm._v(_vm._s(option.email))])]):_vm._e(),(option.phone)?_c('b-card-text',{staticClass:"mb-0 text-muted"},[_c('small',[_vm._v(_vm._s(option.phone))])]):_vm._e()],1)],1)]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.desc)+" ")]}}],null,true),model:{value:(_vm.consultor),callback:function ($$v) {_vm.consultor=$$v},expression:"consultor"}},[(_vm.agents.length > 0)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")]):_vm._e(),(_vm.agents.length === 0)?_c('template',{slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Digite o nome'))+" ")]):_vm._e()],2)],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Valor do negócio')}},[_c('b-form-spinbutton',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"min":"0","max":"100","formatter-fn":_vm.formatValorNegocio},model:{value:(_vm.valorNegocio),callback:function ($$v) {_vm.valorNegocio=$$v},expression:"valorNegocio"}})],1)]}}],null,true)})],1)],1),(_vm.hubs.length > 0)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('HUB de origem')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.hubs,"append-to-body":"","calculate-position":_vm.withPopper,"label":"sw002s03","item-text":"sw002s03","item-value":"sw002s01"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var sw002s03 = ref.sw002s03;
return [_vm._v(" "+_vm._s(sw002s03)+" ")]}}],null,true),model:{value:(_vm.hubOrigem),callback:function ($$v) {_vm.hubOrigem=$$v},expression:"hubOrigem"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)]}}],null,true)})],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('Intenção da referência')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataIntencao,"append-to-body":"","calculate-position":_vm.withPopper,"label":"txt","item-text":"txt","item-value":"id"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var txt = ref.txt;
return [_vm._v(" "+_vm._s(txt)+" ")]}}],null,true),model:{value:(_vm.intencaoReferencia),callback:function ($$v) {_vm.intencaoReferencia=$$v},expression:"intencaoReferencia"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Observações')}},[_c('b-form-textarea',{attrs:{"rows":"3","no-resize":""},model:{value:(_vm.observacao),callback:function ($$v) {_vm.observacao=$$v},expression:"observacao"}})],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }